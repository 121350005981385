import checkMarkIcon from "Assets/Images/arrow_drop_down.svg";
import errorIcon from "Assets/Images/error.svg";
import { Input } from "Components/Atoms/Input/Input";
import React from "react";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import dail_icon from "Assets/Images/dail_icon.svg";
import styles from "./PhoneCode.module.scss";
import { PatternFormat } from "react-number-format";
import { UseOutSideClick } from "../UseOutsideClick/UseOutsideClick";
import ReactCountryFlag from "react-country-flag";

export function PhoneCode({
  label,
  className,
  id,
  optional = false,
  miniText = false,
  message,
  error,
  errorClassName,
  disabled,
  options = [
    { id: 1, key: "US", value: "+1", type: "PCH" },
    { id: 2, key: "Canada", value: "+123", type: "PCH" },
    { id: 3, key: "India", value: "+1234", type: "PCH" },
  ],
  textSmall = false,
  onCodeChange = () => {},
  selectedCode = {},
  showEditIcon = false,
  ...props
}) {
  const [isActive, setIsActive] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [selectedOption, setSelectedOption] = useState({});
  const selectRef = useRef(null);
  const phoneRef = useRef(null);
  const dropdownRef = useRef(null);
  const [phoneNumberFormat, setPhoneNumberFormat] = useState("(###) ###-####");
  const [filteredList, setFilteredList] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const [labelTop, setLabelTop] = useState(false);
  const [codeError, setCodeError] = useState("");
  const [clickCount, setClickCount] = useState(0);

  //   eslint-disable-next-line
  const { value } = props;
  UseOutSideClick(dropdownRef, () => {
    if (isActive) {
      setIsActive(false);
    }
  });

  useEffect(() => {
    if (selectedOption?.name === "US") {
      setPhoneNumberFormat("(###) ###-####");
    } else {
      setPhoneNumberFormat("## ####-#########");
    }
  }, [selectedOption]);

  const handleOptionSelect = (option) => {
    setIsActive(false);
    setIsHover(false);
    setSelectedOption(option);
    setClickCount(clickCount + 1);
    onCodeChange(option, clickCount);
    setCodeError("");
  };

  const handleBlur = (e) => {
    setIsFocused(false);
    setIsActive(false);
    setLabelTop(e.target.value);

    if (Object.keys(selectedOption).length === 0 && !optional) {
      setCodeError("Please select country code");
    } else {
      setCodeError("");
    }
    // eslint-disable-next-line
    const { onBlur } = props;
    if (typeof onBlur === "function") {
      onBlur(e);
    }
  };

  useEffect(() => {
    setFilteredList(options);
  }, [options]);

  useEffect(() => {
    if (!isActive) {
      setFilteredList(options);
    }
    // eslint-disable-next-line
  }, [isActive]);

  const handleFocus = (e) => {
    setIsFocused(true);
    setLabelTop(true);
    // eslint-disable-next-line
    const { onFocus } = props;
    if (typeof onFocus === "function") {
      onFocus(e);
    }
  };
  useEffect(() => {
    setLabelTop(isFocused ? isFocused : !!value);
    // eslint-disable-next-line
  }, [props]);

  useEffect(() => {
    if (selectedCode) {
      setSelectedOption(selectedCode);
    }
  }, [selectedCode]);

  return (
    <div
      className="flex gap-8 max-md:gap-2 w-[100%] relative"
      ref={dropdownRef}
    >
      <div
        className={`${
          styles.phone_selected_option
        } rounded-[4px] pr-2  font-medium ${
          disabled ? "text-cgy2  cursor-auto fontSize" : "text-cgy4"
        } ${textSmall ? "text-xs" : "text-sm"} min-w-[132px]  cursor-pointer h-[56px] max-md:h-[34px] max-md:!min-w-[68px]`}
        onClick={() => {
          setIsActive(!isActive);
        }}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <img
          alt="checkMarkIcon"
          src={checkMarkIcon}
          className={`transform font-light icon-arrow-down text-xs 
          ${isActive && "!rotate-180"} max-md:w-[14px] max-md:h-[14px] ${
            isHover || isActive ? "text-cm3" : "text-cgy3"
          }`}
          onClick={() => setIsActive(!isActive)}
        />
        <div className=" text-[32px] max-md:text-[15px]">
          <ReactCountryFlag
            countryCode={selectedOption?.name}
            svg
            cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
            cdnSuffix="svg"
            title={selectedOption?.name}
          />
        </div>
        <div
          className={`${styles.phoneSelected} max-md:text-[9px]`}
        >
          {`+${selectedOption?.phoneCountryCode || "--"} `}
        </div>
      </div>
      <div
        className={`${styles.form_select_container} ${className} group w-[100%]`}
      >
        <div
          className={`${styles.form_select} !pl-0
  ${
    isFocused && showEditIcon
      ? styles.paymentFocus
      : isFocused
        ? styles.focus
        : ""
  }
  ${error && styles.error}
  ${disabled && styles.disabled} ${showEditIcon && "hover:border-cm11"}`}
          tabIndex="0"
        >
          {label && (
            <label
              className={` ${
                styles.label
              } px-1 bg-cwhite absolute transform transition-all duration-100 ease-in
      ${
        labelTop
          ? `-top-[9px]  left-3 ${styles.text_xxs}`
          : `left-[83px] ${
              miniText
                ? "5xl:text-[8px] 3xl:text-[13px] top-3"
                : "text-sm top-1/4"
            }`
      }
      ${
        disabled
          ? "text-cgy2 pointer-events-none"
          : labelTop
            ? "text-cgy4"
            : "text-cgy3"
      }`}
              htmlFor={id}
            >
              {label}
            </label>
          )}

          <div className="flex justify-center w-[100%]">
            <div className="flex items-center justify-center ml-3">
              <img src={dail_icon} className="max-md:w-[12px]" alt="mobileIcon" />
            </div>
            <PatternFormat
              {...props}
              id={id}
              getInputRef={phoneRef}
              customInput={Input}
              format={phoneNumberFormat}
              onFocus={handleFocus}
              onBlur={handleBlur}
              // eslint-disable-next-line
              placeholder={props.placeholder}
              className={`${
                disabled ? "text-cgy2" : "text-cgy4"
              }  pl-2 pt-[9px] max-md:pt-1 max-md:pb-1   pb-2 ${styles.PhoneWithCode} !font-medium xs:text-[16px] xl:text-[20px] max-md:text-[12px]`}
            />
            {error && (
              <div className="flex items-center justify-end">
                <img src={errorIcon} className="w-4 h-4" alt="errorIcon" />
              </div>
            )}
            {!isFocused && (
              <button
                type="button"
                className={`absolute invisible transform -translate-y-1/2 cursor-pointer text-cm3 icon-edit-icon-new top-1/2 right-2 text-xs ${
                  showEditIcon && "group-hover:visible"
                }`}
                onClick={() => {
                  setIsFocused(true);
                  phoneRef.current?.focus();
                }}
              />
            )}
          </div>
        </div>
      </div>
      {isActive && (
        <div
          className={`absolute top-[67px]  max-md:w-[172px]  max-md:h-[180px] overflow-y-auto  max-md:top-[36px] w-[240px] ${styles.phone_options_container}`}
          ref={selectRef}
        >
          <div className={`${styles.options_wrap}`}>
            {filteredList.length > 0 ? (
              filteredList.map((x, i) => (
                <div
                  key={i}
                  className={`flex items-center px-3 py-4   max-md:px-[6px]  max-md:py-2 border-b-[1px] justify-start cursor-pointer font-medium ${
                    selectedOption?.phoneCountryCode === x?.phoneCountryCode &&
                    selectedOption?.country === x?.country &&
                    "bg-[#F7FCF7D9]"
                  } ${textSmall ? "text-xs" : "text-sm"}  max-md:text-[8px]`}
                  onClick={() => handleOptionSelect(x)}
                >
                  <span className=" text-[32px]  max-md:text-[15px] mr-4">
                    <ReactCountryFlag
                      countryCode={x?.name}
                      svg
                      cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                      cdnSuffix="svg"
                      title={x?.name}
                    />
                  </span>
                  {`${x?.country}  +${x?.phoneCountryCode}`}
                </div>
              ))
            ) : (
              <div className="flex items-center justify-center h-[70px]">
                <p className={styles.noSearchResult}>
                  Search results not found
                </p>
              </div>
            )}
          </div>
          <div className="w-full h-2 opacity-0"></div>
        </div>
      )}
      {!isActive && (
        <span
          className={`
  ${styles.text_xxs}   ${styles.errorGap} absolute  text-ellipsis !font-medium !-bottom-[20px] !left-0 text-[16px] max-md:text-[12px]
  ${(error || codeError) && "text-[#BC0000]"} ${errorClassName}`}
        >
          {error ? error : codeError || message}
        </span>
      )}
    </div>
  );
}

PhoneCode.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  showSearchIcon: PropTypes.bool,
  optional: PropTypes.bool,
  miniText: PropTypes.bool,
  message: PropTypes.string,
  error: PropTypes.string,
  errorClassName: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ),
  textSmall: PropTypes.bool,
  onCodeChange: PropTypes.func,
  selectedCode: PropTypes.object,
  showEditIcon: PropTypes.bool,
};
