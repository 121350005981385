import Loader from "Components/Atoms/Loader/Loader";
import React, { Suspense, useEffect, useState } from "react";
import { Route, Routes, useLocation, HashRouter } from "react-router-dom";
import ContactPage from "Pages/ContactPage/ContactPage";
import { PrivicyPolicy } from "Pages/PrivicyPolicy/PrivicyPolicy";
import { LegalTerms } from "Pages/LegalTerms/LegalTerms";

const Internal = React.lazy(
  () => import("Components/Templates/Internal/Internal")
);
const VitalSigns = React.lazy(
  () => import("Pages/HealthDataPlatform/VitalSigns/VitalSigns")
);
const Home = React.lazy(() => import("Pages/Home/Home"));
const WellnessPage = React.lazy(
  () => import("Pages/WellnessPage/WellnessPage")
);

function ScrollToTop() {
  const location = useLocation();
  const [triggerScroll, setTriggerScroll] = useState(false);

  useEffect(() => {
    if (triggerScroll) {
      window.scrollTo(0, 0);
      setTriggerScroll(false);
    }
  }, [triggerScroll]);

  useEffect(() => {
    setTriggerScroll(true);
  }, [location]);

  return null;
}

function App() {
  return (
    <HashRouter>
      <ScrollToTop />
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<Internal />}>
            <Route path="/" element={<Home />} />
            <Route path="/health-coach/vital-signs" element={<VitalSigns />} />
            <Route
              path="/health-coach/wellness-score"
              element={<WellnessPage />}
            />
            <Route path="/contact-us" element={<ContactPage />} />
            <Route path="/privacy-policy" element={<PrivicyPolicy />} />
            <Route path="/legal-terms" element={<LegalTerms />} />
          </Route>
        </Routes>
      </Suspense>
    </HashRouter>
  );
}

export default App;
