import PdfViewer from "Components/Atoms/PdfModal/PdfModal";
import React from "react";

export const PrivicyPolicy = () => {
  // eslint-disable-next-line no-undef
  const pdfUrl = process.env.REACT_APP_PRIVACY_POLICY_PDF;
  return (
    <div className="">
      <div className=" pdf-container">
        <div className="container px-4 mx-auto">
          <PdfViewer fileUrl={pdfUrl}  />
        </div>
      </div>
    </div>
  );
};
