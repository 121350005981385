import React from 'react'
import ReactDOM from 'react-dom/client'
import 'react-alice-carousel/lib/scss/alice-carousel.scss'
import App from './App'
import './index.scss'
import './tailwind.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
