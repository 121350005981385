import PdfViewer from "Components/Atoms/PdfModal/PdfModal";
import React from "react";

export const LegalTerms = () => {
  // eslint-disable-next-line no-undef
  const pdfUrl = process.env.REACT_APP_TERMS_AND_SERVICE_PDF;
  return (
    <div className="">
      <div className="pdf-container">
        <div className="container px-4 mx-auto">
          <PdfViewer fileUrl={pdfUrl} />
        </div>
      </div>
    </div>
  );
};
