import contactUs from "Assets/Images/contactus_bg.png";
import humanIcon from "Assets/Images/human.svg";
import macImage from "Assets/Images/macBgWithOpacity.png";
import tabBg from "Assets/Images/tab_bg_image.png";
import mailIcon from "Assets/Images/mail.svg";
import mobile_tab_bg from "Assets/Images/mobile_tab_bg.png";
import { useMediaQuery } from "Hooks/useMediaQuery";
import { getCountryDataList, getEmojiFlag } from "countries-list";
import { useEffect, useState } from "react";
import styles from "./ContactPage.module.scss";
import React from "react";


import {
  FormField,
  FormSelect,
  FormTextArea,
} from "Components/Atoms/FormField/FormField";
import { PhoneCode } from "Components/Atoms/PhoneCode/PhoneCode";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./ContactPage.style.scss";
import { useNavigate } from "react-router-dom";

export default function ContactPage() {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const isTab = useMediaQuery("(max-width: 1080px)");
  const isMAc = useMediaQuery("(max-width: 1740px)");
  const isDesktop = useMediaQuery("(min-width: 1740px)");
  const [bgImage, setBgImage] = useState("");
  const navigateTo = useNavigate();

  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  const updateViewportHeight = () => {
    setViewportHeight(window.innerHeight);
  };

  useEffect(() => {
    setViewportHeight(window.innerHeight);
    window.addEventListener("resize", updateViewportHeight);
    return () => {
      window.removeEventListener("resize", updateViewportHeight);
    };
  }, []);

  useEffect(() => {
    if (isMobile) {
      setBgImage(mobile_tab_bg);
    } else if (isTab) {
      setBgImage(tabBg);
    } else if (isMAc) {
      setBgImage(macImage);
    } else if (isDesktop) {
      setBgImage(contactUs);
    } else {
      setBgImage("");
    }
  }, [isMAc, isDesktop, isMobile, isTab]);

  const [isSucess, setIsSucess] = useState(false);
  const [countryCodeOptions, setCountryCodeOptions] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const [selectedCode, setSelectedode] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);

  const handleChange = () => {
    setIsChecked(!isChecked);
  };

  const [loading, setLoading] = useState(true);

  const handleImageLoad = () => {
    setLoading(false);
  };

  useEffect(() => {
    const countries = getCountryDataList();

    const sortedList = countries?.sort((a, b) =>
    a?.name?.localeCompare(b?.name)
  );
  
    const extractedData = sortedList?.map((country) => {
      return {
        country: country?.name,
        phoneCountryCode: country?.phone[0],
        name: country?.iso2,
        countryFlag: getEmojiFlag(country?.iso2),
      };
    });

    const extractedCountries = sortedList?.map((country) => {
      return {
        label: country?.name,
        value: country?.name,
      };
    });
    setCountriesList(extractedCountries);
    setCountryCodeOptions(extractedData);

    const usCountry = extractedData?.filter((data) => data?.name === "US");

    setSelectedode(usCountry[0]);
    const initialCountry = {
      label: usCountry[0]?.country,
      value: usCountry[0]?.country,
    };
    formik.setFieldValue("country", initialCountry);
    // eslint-disable-next-line
  }, []);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      email: "",
      lastName: "",
      companyName: "",
      phoneNumber: "",
      country: "",
      countryCode: "",
      feedback: "",
    },
    validationSchema: Yup.object({
      phoneNumber: Yup.string()
        .required("Mobile number is required.")
        .matches(/^(?!\s+$)/, "Cannot contain only blankspaces")
        .test(
          "len",
          selectedCode?.name !== "US"
            ? "Mobile number must be between 7 and 15 digits."
            : "Mobile number must be 10 digits.",
          (value) =>
            selectedCode?.name !== "US"
              ? value?.replace(/[^\d]/g, "")?.toString().length >= 7 &&
                value?.replace(/[^\d]/g, "")?.toString().length <= 15
              : value?.replace(/[^\d]/g, "")?.toString().length === 10
        ),
      feedback: Yup.string()
        .required("Feedback is required.")
        .matches(/^(?!\s+$)/, "Cannot contain only blankspaces"),
      firstName: Yup.string()
        .required("First name is required.")
        .matches(/^(?!\s+$)/, "Cannot contain only blankspaces")
        .matches(/^[A-Za-z ]+$/g, "Invalid first name"),
      lastName: Yup.string()
        .required("Last name is required.")
        .matches(/^(?!\s+$)/, "Cannot contain only blankspaces")
        .matches(/^[A-Za-z ]+$/g, "Invalid last name"),
      country: Yup.object().required("Please select your Country"),
      email: Yup.string()
        .email("Invalid email address provided.")
        .required("Email is required.")
        .matches(
          /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,6}$)+$/,
          "Invalid Email"
        ),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = async (values) => {
    setApiLoading(true);
    try {
      const response = await axios.post(
        // eslint-disable-next-line no-undef
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/web/contact-us`,
        {
          firstName: values?.firstName,
          lastName: values?.lastName,
          phoneNumber: values?.phoneNumber?.trim(),
          countryCode: `+${selectedCode?.phoneCountryCode}`?.trim(),
          country: values?.country?.value,
          email: values?.email,
          feedback: values?.feedback,
        },
        {
          headers: {
            "Content-Type": "application/json",
            
          },
        }
      );
      setApiLoading(false);
      setIsSucess(true);
      window.scrollTo(0, 0);
      console.log(response);
    } catch (error) {
      console.error("Error:", error);
      setApiLoading(false);
      setIsSucess(false);
    }
  };

  return (
    <div
      className={`flex flex-col items-center justify-center w-full max-w-[1920px]  mx-auto`}
    >
      <div
        className={`w-full  ${!isSucess && "max-md:min-h-[1130px]"} relative bg-[#f4fcf5]  `}
      >
        <div>
          <div
            className={`${styles.imageContainerMobile} ${isSucess && styles.imageSuccess} h-full  ${
              loading &&
              "max-5xl:min-h-[900px] max-lg:min-h-[800px] max-xl:min-h-[675px] max-xxl:min-h-[787px] max-4xl:min-h[787px]  max-md:min-h-[560px]"
            }`}
          >
            <img
              src={bgImage}
              alt="lady"
              onLoad={handleImageLoad}
              className={`w-full bg-cover h-full ${viewportHeight > 720 && isSucess ? "max-md:!h-[100vh]" : "max-md:!h-full"}`}
            />
            <div
              className={`absolute top-0  left-0 flex  justify-center w-full mt-[10%] max-4xl:mt-[12%] max-md:mt-[20%] ${isSucess && "!mt-[-10%] h-full"}`}
            >
              {!isSucess ? (
                <div
                  className={`flex ${(isMobile || isTab) && "flex-col"} ${
                    isMAc ? "gap-[32px] w-[90%]" : "gap-[12px]  max-w-[1550px]"
                  } max-md:!gap-[32px] justify-between items-center`}
                >
                  <div
                    className={`flex flex-col  max-xxl:w-[45%]  w-[50%] max-xl:w-[90%] max-md:w-full  max-xl:items-center items-start justify-center  break-words text-mp5`}
                  >
                    <p
                      className={`font-semibold max-md:text-[16px] max-md:leading-[32px] max-lg:text-[26px] max-xxl:text-[52px] max-xxl:leading-[72px] text-[36px] max-4xl:text-[52px] 4xl:text-[60px] 4xl:leading-[80px] `}
                    >
                      Your Feedback Matters
                    </p>
                    <p
                      className={`mt-[44px] max-md1:mt-[5px] font-medium max-md:text-[12px] max-md:leading-5 max-xl:leading-[42px] max-xl:text-center max-lg:text-[18px] max-lg:leading-[32px] max-xxl:text-[32px] max-xxl:leading-[48px] max-4xl:text-[32px] 4xl:text-[36px] 4xl:leading-[52px] text-[28px] leading-[42px]

                  `}
                    >
                      {`We're always striving to enhance the Mercola Health Coach
                      app and provide you with the best tools and resources for
                      your health journey`}
                    </p>
                  </div>

                  <div className=" bg-mp4  rounded-[16px] border-[2px] border-[#367561] max-xxl:w-[55%] w-[50%] max-xl:w-[90%] px-[32px] py-[32px] max-4xl:px-[32px] max-md:px-[20px] max-md:py-[20px]  max-4xl:py-[26px]">
                    <div>
                      <p className=" text-[28px] xxl:text-[24px] font-medium leading-[35px] mb-8 max-m1:mb-[13px] max-4xl:text-[24px] max-md:leading-[18px] max-md:text-[12px] max-xxl:text-[21px]">
                        Please fill in the form below to get in touch with us.
                      </p>
                      <div className="flex max-md:flex-col justify-between w-[100%] gap-[5%] max-m1:mb-0 mb-2.5">
                        <div className="w-[50%] max-md:w-full">
                          <p
                            className={` formLabelSize max-md:text-[11px] max-md:leading-[15px]  font-medium  leading-6 mb-1 ${
                              formik.touched["firstName"] &&
                              formik.errors["firstName"] &&
                              "text-[#BC0000]"
                            }`}
                          >
                            First Name{" "}
                            <span className=" text-[13px] text-[#DC0000] font-medium">
                              *
                            </span>
                          </p>
                          <FormField
                            placeholder="Enter Your First Name"
                            icon={humanIcon}
                            inputClass="capitalize !font-medium"
                            {...formik.getFieldProps("firstName")}
                            className="max-md:h-[34px] h-[56px]"
                            errorClass={"max-md:text-[12px]"}
                            error={
                              formik.touched["firstName"] &&
                              formik.errors["firstName"]
                            }
                            maxLength={255}
                          />
                        </div>
                        <div className="w-[50%] max-md:w-full">
                          <p
                            className={` formLabelSize max-md:text-[11px] max-md:leading-[15px]    font-medium  leading-6 mb-1 ${
                              formik.touched["lastName"] &&
                              formik.errors["lastName"] &&
                              "text-[#BC0000]"
                            }`}
                          >
                            Last Name{" "}
                            <span className=" text-[13px] text-[#DC0000] font-medium">
                              *
                            </span>
                          </p>
                          <FormField
                            placeholder="Enter Your Last Name"
                            icon={humanIcon}
                            className="max-md:h-[34px] h-[56px]"
                            inputClass="capitalize"
                            errorClass={"max-md:text-[12px]"}
                            {...formik.getFieldProps("lastName")}
                            error={
                              formik.touched["lastName"] &&
                              formik.errors["lastName"]
                            }
                          />
                        </div>
                      </div>

                      <div className="flex justify-between max-md:flex-col w-full gap-[5%] max-m1:mb-0 mb-2.5">
                        {" "}
                        <div className="w-[50%] max-md:w-full">
                          <p
                            className={` formLabelSize max-md:text-[11px] max-md:leading-[15px] font-medium leading-6 mb-1 ${
                              formik.touched["email"] &&
                              formik.errors["email"] &&
                              "text-[#BC0000]"
                            }`}
                          >
                            Email&nbsp;
                            <span className=" text-[13px] text-[#DC0000] font-medium">
                              *
                            </span>
                          </p>
                          <FormField
                            icon={mailIcon}
                            placeholder="Enter Your Email"
                            className="max-md:h-[34px] h-[56px]"
                            errorClass={"max-md:text-[12px]"}
                            {...formik.getFieldProps("email")}
                            error={
                              formik.touched["email"] && formik.errors["email"]
                            }
                          />
                        </div>
                        <div className="w-[50%] max-md:mb-0 max-md:w-full">
                          <p
                            className={` formLabelSize max-md:text-[11px] max-md:leading-[15px] font-medium leading-6 mb-1 ${
                              formik.touched["country"] &&
                              formik.errors["country"] &&
                              "text-[#BC0000]"
                            }`}
                          >
                            Country&nbsp;
                            <span className="text-[13px] text-[#DC0000] font-medium">
                              *
                            </span>
                          </p>
                          <FormSelect
                            placeholder="Select Country"
                            options={countriesList}
                            value={formik?.values?.country}
                            onChange={(option) => {
                              const selectedCode = countryCodeOptions?.filter(
                                (data) => data?.country === option?.value
                              );
                              setSelectedode(selectedCode[0]);
                              formik.setFieldValue("phoneNumber", "");
                              formik.setFieldValue("country", option);
                            }}
                            onBlur={() => {
                              formik.setFieldTouched("country");
                            }}
                            error={
                              formik.touched["country"] &&
                              formik.errors["country"]
                            }
                          />
                        </div>
                      </div>

                      <div className="flex  justify-center items-center w-full gap-[10%] max-md:flex-col max-md:mb-[26px] mb-[38px]">
                        {" "}
                        <div className="w-[100%]">
                          <p
                            className={` formLabelSize max-md:text-[11px] max-md:leading-[15px] font-medium leading-6 mb-1 ${
                              formik.touched["phoneNumber"] &&
                              formik.errors["phoneNumber"] &&
                              "text-[#BC0000]"
                            }`}
                          >
                            Mobile Number{" "}
                            <span className=" text-[13px] text-[#DC0000] font-medium">
                              *
                            </span>
                          </p>
                          <PhoneCode
                            placeholder={"Enter Mobile Number"}
                            id="phoneNumber"
                            options={countryCodeOptions}
                            className="mb-0 font-medium shadow-none h-[56px max-md:h-[34px] max-md:text-[12px]] "
                            selectedCode={selectedCode}
                            onCodeChange={(option, clickCount) => {
                              const selectedCountry = {
                                label: option?.country,
                                value: option?.country,
                              };
                              formik.setFieldValue("country", selectedCountry);
                              setSelectedode(option);
                              formik.setFieldValue(
                                "countryCode",
                                option?.countryShortCode
                              );
                              if (clickCount >= 0) {
                                formik.setFieldValue("phoneNumber", "");
                              }
                            }}
                            {...formik.getFieldProps("phoneNumber")}
                            error={
                              formik.touched["phoneNumber"] &&
                              formik.errors["phoneNumber"]
                            }
                          />
                        </div>
                      </div>

                      <div className="mb-[40px] max-md:mb-[32px]">
                        {" "}
                        <div>
                          <p
                            className={` formLabelSize max-md:text-[11px] max-md:leading-[15px]  font-medium mb-1  leading-6 ${
                              formik.touched["feedback"] &&
                              formik.errors["feedback"] &&
                              "text-[#BC0000]"
                            }`}
                          >
                            Feedback&nbsp;
                            <span className=" text-[13px] text-[#DC0000] font-medium">
                              *
                            </span>
                            <div>
                              <FormTextArea
                                className={
                                  "h-[108px] mt-1 max-4xl:h-[95px] max-md:h-[139px] max-md:text-[12px]"
                                }
                                placeholder={"Please Type the Feedback"}
                                {...formik.getFieldProps("feedback")}
                                error={
                                  formik.touched["feedback"] &&
                                  formik.errors["feedback"]
                                }
                              />
                            </div>
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-col mb-1">
                        <div className="flex">
                          <input
                            type="checkbox"
                            className={"custom_checkbox mt-[0.5px] m3:mt-0.5"}
                            checked={isChecked}
                            onChange={handleChange}
                          />

                          <p className="ml-2 max-md:ml-[6px] leading-[20px] font-normal text-[14px] max-4xl:text-[14px] max-md:text-[12px] max-md:leading-[17px] text-[#000000]">
                            I would like to receive marketing communications
                            from Mercola.
                          </p>
                        </div>
                        <p className="text-[14px] text-[#000000] max-4xl:text-[14px] font-normal max-md:text-[12px] max-md:leading-[17px]  leading-[20px]">
                          By submitting this form, you acknowledge that you have
                          read the{" "}
                          <span className="text-[#367561]  ">
                            <button
                              className="underline "
                              onClick={() => navigateTo("/privacy-policy")}
                            >
                              Privacy Policy.
                            </button>
                          </span>
                        </p>
                      </div>

                      <div className="flex items-center justify-center mt-[32px] mb-1 max-m1:mt-[24px]">
                        <button
                          className={` ${!(formik.dirty && formik.isValid && isChecked) ? "bg-[#7F8588]" : "bg-[#367561]"} max-md:h-[51px] w-[270px] h-[50px] rounded-lg text-[20px] text-[#FFFFFF] font-semibold`}
                          disabled={
                            !(formik.dirty && formik.isValid && isChecked)
                          }
                          onClick={formik.handleSubmit}
                        >
                          {!apiLoading ? "Submit" : "Submitting..."}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex items-center justify-center mx-auto my-auto text-center">
                  {" "}
                  <div>
                    {" "}
                    <p className=" text-[68px] font-semibold leading-[92px] mb-[44px] max-md:text-[16px] max-md:mb-[20px] max-md:leading-[28px]">
                      Thank you!
                    </p>{" "}
                    <p className=" text-[40px] font-medium leading-[64px] max-md:text-[12px] max-md:leading-[20px]">
                      One of our representatives <br />
                      will contact you shortly.
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
