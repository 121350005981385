import check_mark_icon from "Assets/Images/swipe_down.svg";
import check_mark from 'Assets/Images/check_mark.svg'
import { Input } from "Components/Atoms/Input/Input";
import React from "react";
import {
  alphabets,
  numbers,
  symbolPassCode,
  symbols,
} from "Schemas/keyboard-keys";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import styles from "./FormField.module.scss";

export function FormField({
  id = "",

  message = "",
  error = "",

  disableNumbers = false,
  disableAlphabets = false,
  disableSymbols = false,
  disableSpace = false,
  disablePasswordSymbols = false,
  className = "",
  disabled = false,
  successMessage,
  maxLength,
  isPaste = true,
  errorClass = "",
  isCopy = true,
  isCut = true,
  successValidation = false,
  placeholder = "",
  icon = "",
  isPasswordVisible = false,
  inputClass = "",
  PlaceHolderFont = false,
  ...props
}) {
  const inputRef = useRef(null);
  const handleBlur = (e) => {
    const { onBlur } = props;
    if (typeof onBlur === "function") {
      onBlur(e);
    }
  };

  const handleFocus = () => {
    //   setIsFocused(true);
    const { onFocus } = props;
    if (typeof onFocus === "function") {
      onFocus();
    }
  };

  const handleInputKeyDown = (e) => {
    if (disableSymbols) {
      [...symbols, "_"].includes(e.key) && e.preventDefault();
    }
    if (disableSpace) {
      e.which === 32 && e.preventDefault();
    }
    if (disableNumbers) {
      [...numbers].includes(e.key) && e.preventDefault();
    }
    if (disableAlphabets) {
      [...alphabets].includes(e.key) && e.preventDefault();
    }
    if (disablePasswordSymbols) {
      [...symbolPassCode].includes(e.key) && e.preventDefault();
    }
  };

  const handleOnPaste = (e) => {
    if (!isPaste) {
      e.preventDefault();
      return false;
    }
  };

  const handleOnCopy = (e) => {
    if (!isCopy) {
      e.preventDefault();
      return false;
    }
  };

  const handleOnCut = (e) => {
    if (!isCut) {
      e.preventDefault();
      return false;
    }
  };

  return (
    <div
      className={`
          ${styles.form_field} 
          ${error && styles.error} 
          ${successValidation && successMessage && !error && styles.success}
          ${disabled && styles.disabled}
          ${
            !error &&
            `hover:!border-cm11 hover:shadow-[0px_1px_3px_var(--cgy6)]`
          }
          ${className}`}
    >
      <img
        src={icon}
        alt="humanize"
        className="w-6 h-6 max-md:w-[12px] max-md:h-[12px] mr-4"
      />
      <Input
        {...props}
        placeholder={`${
          placeholder
        } `}
        id={id}
        onPaste={handleOnPaste}
        onCopy={handleOnCopy}
        onCut={handleOnCut}
        className={`!font-medium xs:text-[16px] xl:text-[20px] max-md:text-[12px]  ${placeholder && styles.normal_placeholder} ${inputClass} ${
          styles.input
        } ${isPasswordVisible && "w-10/12"} ${
          PlaceHolderFont ? styles.placeHolderClassName : styles.placeholder
        } 
          `}
        disabled={disabled}
        ref={inputRef}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={handleInputKeyDown}
        maxLength={maxLength}
      />

      <span
        className={`${errorClass}
          ${styles.text_xxs} ${
            styles.errorGap
          } left-0 absolute truncate  leading-[25px] text-[16px]  font-medium
          ${error && "text-[#BC0000]"}`}
      >
        {error || message}
      </span>
      {successValidation && !error && (
        <span
          className={`
          ${styles.text_xxs} ${
            styles.errorGap
          } absolute left-3 truncate font-medium
          ${successValidation && "text-cgn5 font-medium"}`}
        >
          {successMessage}
        </span>
      )}
    </div>
  );
}

FormField.propTypes = {
  id: PropTypes.string,
  message: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  disableNumbers: PropTypes.bool,
  disableAlphabets: PropTypes.bool,
  disableSymbols: PropTypes.bool,
  disableSpace: PropTypes.bool,
  disablePasswordSymbols: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  successMessage: PropTypes.string,
  maxLength: PropTypes.number,
  isPaste: PropTypes.bool,
  errorClass: PropTypes.string,
  isCopy: PropTypes.bool,
  isCut: PropTypes.bool,
  successValidation: PropTypes.bool,
  placeholder: PropTypes.string,
  icon: PropTypes.string,
  isPasswordVisible: PropTypes.bool,
  inputClass: PropTypes.string,
  placeholderWithoutLabel: PropTypes.string,
  PlaceHolderFont: PropTypes.bool,
  value: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
};

export function FormTextArea({
  id,
  className,
  value,
  error,
  disabled = false,
  disableNumbers = false,
  disableAlphabets = false,
  disableSymbols = false,
  isBold = false,
  textClassName = "",
  textArea = false,
  placeHolderFont = false,
  errorFontClass = "",
  ...rest
}) {

  const handleBlur = (e) => {
    //   setIsFocused(false);
    const { onBlur } = rest;
    if (typeof onBlur === "function") {
      onBlur(e);
    }
  };

 

  const handleChange = (e) => {
    const { onChange } = rest;
    if (typeof onChange === "function") {
      onChange(e);
    }
  };

  const handleKeyDown = (e) => {
    if (disableSymbols) {
      [...symbols].includes(e.key) && e.preventDefault();
    }
    if (disableNumbers) {
      [...numbers].includes(e.key) && e.preventDefault();
    }
    if (disableAlphabets) {
      [...alphabets].includes(e.key) && e.preventDefault();
    }
  };


  return (
    <div className={`relative ${styles.textarea_wrap} ${className}`}>
      <textarea
        {...rest}
        id={id}
        value={value}
        placeholder={
          rest.placeholder
        }
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        className={`${
          textArea ? styles.form_field_textarea : styles.form_textarea
        } 
          ${textClassName}
          ${error && styles.error} 
          ${disabled && styles.disabled} ${isBold && styles.text_bold} ${
            placeHolderFont ? styles.placeHolderClassName : styles.placeholder
          }
           xs:text-[16px] max-md:font-medium xl:text-[20px]
          `}
      ></textarea>
      <span
        className={`
          ${styles.text_xxs} ${
            styles.errorGap
          } font-medium absolute left-0 truncate max-lg:mb-[3px] text-[16px] max-md:text-[12px]
          ${error && "text-crd5"} ${errorFontClass}`}
      >
        {error}
      </span>
    </div>
  );
}

FormTextArea.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  disableNumbers: PropTypes.bool,
  disableAlphabets: PropTypes.bool,
  disableSymbols: PropTypes.bool,
  isBold: PropTypes.bool,
  textClassName: PropTypes.string,
  textArea: PropTypes.bool,
  placeHolderFont: PropTypes.bool,
  errorFontClass: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};

export function FormSelect({
  label,
  className,
  error,
  disabled,
  options = [
    { value: "Option 1", label: "Option 1" },
    { value: "Option 2", label: "Option 2" },
    { value: "Option 3", label: "Option 3" },
  ],
  value = {},
  textSmall = false,
  placeholder = "",
  onChange = () => {},
  onBlur = () => {},
  disableDropDownOnly = false,
}) {
  const [isActive, setIsActive] = useState(false);
  const [selectedOption, setSelectedOption] = useState({});
  const selectRef = useRef(null);

  // useOutsideClick(selectRef, () => {
  //   setIsActive(false);
  // });

  const handleOptionSelect = (option) => {
    setIsActive(false);
    if (typeof onChange == "function") {
      onChange(option);
    } else {
      setSelectedOption(option);
    }
  };

  const handleBlur = () => {
    setIsActive(false);
    onBlur();
  };

  const handleOnClick = () => {
    if (!disableDropDownOnly) {
      setIsActive(!isActive);
    }
  };

  useEffect(() => {
    setSelectedOption(value);
  }, [value]);

  return (
    <div className={`${styles.form_select_container}`} ref={selectRef}>
      <div
        className={`${styles.form_select} 
          ${isActive && styles.focus}
          ${error && styles.error}
          ${disabled && styles.disabled}`}
        tabIndex="0"
        onBlur={handleBlur}
        onClick={() => setIsActive(!isActive)}
      >
        <div
          className={`px-1 bg-cwhite absolute left-3 transform transition-all duration-100 ease-in ${
            selectedOption ? `-top-2 ${styles.text_xxs}` : "top-2.5 text-sm"
          } ${
            disabled
              ? "text-cgy2 pointer-events-none"
              : selectedOption
                ? "text-cgy4"
                : "text-cgy3"
          }`}

        >
          {label}
        </div>
        <div
          className={`${styles.selected_option} h-[54px] max-md:h-[32px]  ${
            disabled ? "text-cgy2 pointer-events-none fontSize" : "text-cgy4"
          } ${textSmall ? "text-xs" : "text-sm"}`}
          onClick={handleOnClick}
        >
          <div
            className={`${styles.Selected} xs:text-[16px] xl:text-[20px] xs:leading-[20px] xl:leading-[26px] max-md:text-[12px] ${!selectedOption?.label && "text-[#818181]"} font-medium`}
          >
            {selectedOption?.label || placeholder}
          </div>
        </div>
        {!disabled && !disableDropDownOnly && (
          <img
            src={check_mark_icon}
            alt="checkmark"
            className={`absolute transition-transform duration-300 ease-in-out top-3 max-md:top-[2px] right-3 mt-1
                ${!isActive ? "rotate-[0deg]" : "-rotate-[180deg]"}`}
            onClick={() => setIsActive(isActive)}
          />
        )}
        {isActive && (
          <>
            <div className={`${styles.options_wrap} ${className}`}>
              {options?.map((x, i) => (
                <div
                  key={i}
                  className={`flex items-center justify-between  cursor-pointer text-[20px] max-md:text-[12px] font-medium  ${
                    selectedOption?.value === x?.value
                      ? "text-[#357561] !font-bold"
                      : "text-cgy3"
                  } ${textSmall ? "text-xs" : "text-sm text-cgy3"}`}
                  onClick={() => {
                    handleOptionSelect(x);
                  }}
                >
                  {x?.label}
                  {selectedOption?.value === x?.value && (
                      <img src={check_mark} width="15" height="10" alt="checkMark" />
                    )}
                </div>
              ))}
            </div>
            <div className="w-full h-2 opacity-0"></div>
          </>
        )}
      </div>
      <span
        className={`
          ${styles.text_xxs}   ${
            styles.errorGap
          } absolute text-ellipsis -bottom-[44px] max-md:text-[12px] max-md:-bottom-[15px] 
          ${error && "text-[#BC0000] text-[18px] font-medium"}`}
      >
        {error}
      </span>
    </div>
  );
}

FormSelect.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  message: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  value: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  textSmall: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  disableDropDownOnly: PropTypes.bool,
};

