// components/PdfViewer.js
import React from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import PropTypes from "prop-types";
const PdfViewer = ({ fileUrl }) => {
  const pdfjsVersion = "3.11.174";

  return (
    <div className="  min-h-dvh max-md1:pt-[20%] max-xl:pt-[10%] pt-[10%] pb-10  pdf-container">
      <Worker
        workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`}
      >
        <Viewer fileUrl={fileUrl} />
      </Worker>
    </div>
  );
};

export default PdfViewer;

PdfViewer.propTypes = {
  fileUrl: PropTypes.string
};
